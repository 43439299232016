// @ts-nocheck

import React from "react"
import { Box, BoxProps, SimpleGrid } from "@chakra-ui/core"
import { FluidObject } from "gatsby-image"
import { AssetValue, Value } from "contentful-layout-field"
import styled from "@emotion/styled"
import { useInView } from "react-intersection-observer"

import { bp } from "../../utils/MediaQueries"
import MaxWidthGrid from "../Layout/MaxWidthGrid"
import Hero from "../Hero"
import HeadingSection from "../HeadingSection"
import ImageSplitSection from "../ImageSplitSection"
import GridCardSection from "../GridCardSection"
import FullBleedImageSection from "../FullBleedImageSection"
import TopSubTitleHeading from "../BikeProductPage/Title"
import TwoThirdsTextWithImage from "../BikeProductPage/TwoThirdsTextWithImage"
import SplitImageCards from "../BikeProductPage/SplitImageCards"
import ImageTitledTextSplit from "../BikeProductPage/ImageTitledTextSplit"
import FullWidthImageNoBleed from "../BikeProductPage/FullWidthImageNoBleed"
import SpecList from "../BikeProductPage/SpecList"
import BikeSelector from "../BikeProductPage/BikeSelector"
import WhereAndHow from "../BikeProductPage/WhereAndHow"
import QuestionsAndAnswers from "../BikeProductPage/QuestionsAndAnswers"
import FullWidthImageCarouselWithBleed from "../FullWidthImageCarouselWithBleed"
import BikeHeightSelctor from "../BikeHeightSelector"
import ParagraphSection from "../ParagraphSection"
import ImageGrid from "../ImageGrid"
import Embed from "../Embed"
import ImageBlock from "../ImageBlock"
import FigureSection from "../FigureSection"
import ContactForms from "../ContactForms"
import AssemblyInstructions from "../AssemblyInstructions"
import CtaBar from "../CtaBar"
import ArticleBlock from "../ArticleBlock"
import ArticleBlockItem from "../ArticleBlockItem"
import KlaviyoNewsletterForm from "../KlaviyoNewsletterForm"
import GeometrySpecList from "../GeometrySpecList"
import InstagramVideoGrid from "../InstagramVideoGrid"
import BikeListing from "../BikeListing"
import Image360 from "../Image360"
import FullBleedImageSlider from "../FullBleedImageSlider"
import InfoBanner from "../InfoBanner"
import YoutubeReviews from "../YoutubeReviews"
import { useSelector, useDispatch } from "react-redux"
import { BaseRootState, BaseDispatch } from "../../redux/store"
import { useDisclosure } from "@chakra-ui/core"
import BodyFitQuizHorizontal from "../BodyFitQuizHorizontal"
import CustomCTA from "../CustomCTA"
import BikeDetails from "../BikeDetails"
import BikeBulkContent from "../BikeBulkContent"
import BlogRelatedStoriesBlock from "../BlogRelatedStoriesBlock"
import RelatedBikes from "../RelatedBikes"
import PrArticles from "../PrArticles"

type LayoutBuilderProps = BoxProps & {
  data: Value
  imageMap: Record<string, FluidObject>
  variant?: string
  delayRender?: boolean
}

const Container = styled(Box)`
  > .layout-block + .layout-block {
    margin-top: 2.5rem;
    margin-bottom: 2.5rem;
  }

  > .layout-block + .layout-block__image-title-text-split,
  > .layout-block__image-title-text-split
    + .layout-block__image-title-text-split {
    margin-top: 7rem;
    margin-bottom: 7rem;
  }

  > .layout-block__full-bleed-image:last-child {
    margin-top: 5rem;
  }

  > .layout-block__image-360 {
    margin-top: 0rem;
    margin-bottom: 0rem;
  }
`

const DelayWrapper: React.FC = ({ children }) => {
  const { ref, inView } = useInView({
    triggerOnce: true,
    rootMargin: "-50px 0px",
  })

  return <div ref={ref}>{inView && children}</div>
}

const LayoutBuilder = ({
  data,
  imageMap,
  variant,
  delayRender,
  ...props
}: LayoutBuilderProps) => {
  const transformImage = (image: AssetValue, mobile?: boolean) =>
    imageMap[(mobile ? image?.mobileAssetId : image?.assetId) || ""]
  const transformImageWithOptions = (image: AssetValue, mobile?: boolean) => ({
    ...image,
    asset: imageMap[(mobile ? image?.mobileAssetId : image?.assetId) || ""],
  })

  const layoutBlocks = data?.layout?.map((layoutData, index) => {
    const key = layoutData.id
    let layout = layoutData
    const className = `layout-block layout-block__${layout.layoutId}`

    if (variant) {
      const variantConfig = layoutData?.variants?.[variant]
      if (variantConfig?.enabled) {
        //@ts-ignore
        layout = {
          ...layoutData,
          ...variantConfig.value,
        }
      }
    }

    const headingMarginTop =
      index > 0
        ? ["4rem", null, null, null, "6.25rem", "7.5rem", "10rem", "12.5rem"]
        : "0"
    const headingMarginBottom = [
      "2rem",
      null,
      null,
      null,
      "3rem",
      "3.5rem",
      "3.75rem",
      "5rem",
    ]

    // Mobile Quiz drawer
    const { isOpen, onClose, onOpen } = useDisclosure()
    // Redux
    const dispatch = useDispatch<BaseDispatch>()

    const {
      quiz: {
        currentQuestionIndex,
        questions,
        quizProgressState,
        previousQuizAnswers,
        answers,
        currentPreviousAnswersDate,
      },
      bikes: { bikeResults, customizerBike },
      sidebar: { isOpen: sidebarOpen, animate: animateContent },
      user: { accessToken, tokenExpiration },
    } = useSelector((state: BaseRootState) => state)

    switch (layout.layoutId) {
      case "heading": {
        const hasHeading = !!layout.heading?.trim()
        return (
          <HeadingSection
            key={key}
            heading={
              hasHeading ? (
                <span dangerouslySetInnerHTML={{ __html: layout.heading }} />
              ) : undefined
            }
            subtitle={!!layout?.subtitle?.trim() ? layout.subtitle : undefined}
            links={layout.links.map((link) => link.value)}
            mt={hasHeading ? headingMarginTop : "1rem"}
            mb={headingMarginBottom}
          />
        )
      }
      case "top-subtitle-heading": {
        return (
          <TopSubTitleHeading
            key={key}
            id={layout.headingLabel}
            title={layout.heading}
            subTitle={layout.subtitle}
            body={layout.body}
            buttons={layout.links.map(({ value: l }) => ({
              title: l.label,
              url: l.url,
              theme: l.color,
            }))}
            px="1.25rem"
            mt={headingMarginTop}
            mb={headingMarginBottom}
            className="SectionTitleBlock"
          />
        )
      }
      case "hero": {
        return (
          <Hero
            key={key}
            heading={
              !layout.hideHeading ? (
                <span dangerouslySetInnerHTML={{ __html: layout.heading }} />
              ) : undefined
            }
            subtitle={layout.subtitle}
            links={layout.links.map((link) => link.value)}
            images={layout.images.map((image) => ({
              fluid: transformImage(image.value),
              backgroundSize: image.value.options.backgroundSize,
              fluidMobile: transformImage(image.value, true),
              backgroundSizeMobile: image.value.mobileOptions?.backgroundSize,
            }))}
          />
        )
      }
      case "split-image": {
        return (
          <ImageSplitSection
            key={key}
            className={className}
            firstImage={transformImage(layout.leftImage)}
            secondImage={transformImage(layout.rightImage)}
          />
        )
      }
      case "card-grid": {
        return (
          <GridCardSection
            key={key}
            className={className}
            cards={layout.cards.map(({ value }) => ({
              heading: value?.heading || "",
              body: (
                <span dangerouslySetInnerHTML={{ __html: value?.body || "" }} />
              ),
              link: value?.link || "",
              image: transformImage(value?.image),
            }))}
          />
        )
      }
      case "full-bleed-image": {
        return (
          <FullBleedImageSection
            key={key}
            className={className}
            image={transformImage(layout.image)}
            mobileImage={transformImage(layout.image, true)}
            eager={index < 2}
          />
        )
      }
      case "full-bleed-image-slider": {
        const slides = layout?.slides.map((slide) => ({
          image: transformImage(slide?.value?.image),
          mobileImage: transformImage(slide?.value?.image, true),
          linkUrl: slide?.value?.linkUrl,
        }))
        return (
          <FullBleedImageSlider
            key={key}
            slides={slides}
            slideDuration={layout.slideDuration * 1000}
          />
        )
      }
      case "youtube-reviews": {
        const slides = layout.list.map((slide) => (
          {
          avatar: transformImage(slide?.value?.avatar),
          thumbnail: transformImage(slide?.value?.thumbnail),
          author: slide?.value?.author,
          review: slide?.value?.review,
          title: slide?.value?.title,
          ytLink: slide?.value?.ytLink,
        }
        ))
        return (
          <Container {...props} className="SectionTitleBlock">
            <YoutubeReviews
              key={key}
              slides={slides}
              // slideDuration={layout.slideDuration * 1000}
            />
          </Container>
        )
      }
      case "two-thirds-text-with-image": {
        return (
          <TwoThirdsTextWithImage
            key={key}
            className={className}
            image={transformImageWithOptions(layout.image)}
            text={layout.body}
            heading={layout.heading}
          />
        )
      }
      case "two-cards": {
        return (
          <SplitImageCards
            key={key}
            className={className}
            imageLeft={transformImage(layout.leftCardImage)}
            leftTitle={layout.leftCardHeading}
            leftBody={layout.leftCardBody}
            imageRight={transformImage(layout.rightCardImage)}
            rightTitle={layout.rightCardHeading}
            rightBody={layout.rightCardBody}
          />
        )
      }
      case "image-title-text-split": {
        return (
          <ImageTitledTextSplit
            key={key}
            className={className + " ImageTitledTextSplitSection"}
            subHeader={layout.subtitle}
            header={layout.heading}
            body={layout.body}
            image={transformImage(layout.image)}
            imagePosition={
              layout.imageSide !== "left" && layout.imageSide !== "right"
                ? "right"
                : layout.imageSide
            }
          />
        )
      }
      case "full-width-image-with-out-bleed": {
        return (
          <FullWidthImageNoBleed
            key={key}
            className={className}
            image={transformImage(layout.image)}
          />
        )
      }
      case "spec-list": {
        return (
          <SpecList
            key={key}
            className={className}
            // @ts-ignore
            specs={layout.specs.map(({ value }) => ({
              label: value?.label || "",
              value: value?.details || "",
            }))}
          />
        )
      }
      case "geometry": {
        return (
          <GeometrySpecList
            key={key}
            className={className}
            image={transformImage(layout.image)}
            note={layout.note}
            specs={layout.specs.map(({ value }) => ({
              label: value?.label || "",
              details: value?.details || "",
              value: value?.value || "",
            }))}
          />
        )
      }
      case "bike-image-selector": {
        return (
          <BikeSelector
            key={key}
            className={className}
            // @ts-ignore
            pairs={layout.list.map(({ value }) => ({
              image: transformImage(value?.image),
              label: value?.name || "",
            }))}
          />
        )
      }
      case "terrain": {
        return (
          <WhereAndHow
            key={key}
            className={className}
            pathTypes={layout.terrains.map(({ value }) => value)}
            leftBox={{
              box: {
                mainText: layout.leftBoxMainText,
                subText: layout.leftBoxSubText,
              },
              title: layout.leftTitle,
              body: layout.leftBody,
            }}
            rightBox={{
              box: {
                mainText: layout.rightBoxMainText,
                subText: layout.rightBoxSubText,
              },
              title: layout.rightTitle,
              body: layout.rightBody,
            }}
          />
        )
      }
      case "question-and-answers": {
        return (
          <QuestionsAndAnswers
            key={key}
            className={className}
            list={layout.list.map(({ value }) => value)}
          />
        )
      }
      case "image-carousel-with-bleed": {
        return (
          <FullWidthImageCarouselWithBleed
            key={key}
            className={className}
            images={
              layout?.images?.map((image) => transformImage(image.value)) ?? []
            }
          />
        )
      }
      case "bike-height-demo": {
        return (
          <BikeHeightSelctor
            key={key}
            className={className}
            images={layout.heightData.map(({ value }) => ({
              image: transformImage(value?.image),
              label: value?.label || "",
            }))}
            specs={layout.specList.map(({ value }) => value)}
          />
        )
      }
      case "paragraph-section":
        return (
          <MaxWidthGrid key={key} className={className}>
            <ParagraphSection
              className="paragraphSectionWrapper"
              gridColumn={bp("1/end", "3/13")}
              heading={layout.heading}
              mb={0}
            >
              <Box dangerouslySetInnerHTML={{ __html: layout.body }} />
            </ParagraphSection>
          </MaxWidthGrid>
        )
      case "image-grid":
        return (
          <MaxWidthGrid key={key} className={className}>
            <ImageGrid
              gridColumn={bp("1/end", "2/14")}
              images={layout.images.map(({ value }) => ({
                image: transformImage(value?.image),
                url: value?.url || "",
              }))}
            />
          </MaxWidthGrid>
        )
      case "image-block":
        return (
          <MaxWidthGrid key={key} className={className}>
            <ImageBlock
              gridColumn={bp("1/end", "2/14")}
              images={layout.images.map(({ value }) => transformImage(value))}
            />
          </MaxWidthGrid>
        )
      case "embed":
        return (
          <MaxWidthGrid key={key} className={className}>
            <Embed
              gridColumn={bp("1/end", "2/14")}
              code={layout.code}
              responsive
            />
          </MaxWidthGrid>
        )
      case "figures":
        return (
          <MaxWidthGrid key={key} className={className}>
            <FigureSection
              gridColumn={bp("1/end", "2/14")}
              figures={layout.figures.map(({ value }) => ({
                figure: value?.figure || "",
                figureTitle: value?.title || "",
                description: value?.description || "",
              }))}
            />
          </MaxWidthGrid>
        )
      case "contact-forms":
        return (
          <ContactForms
            key={key}
            className={className}
            maxWidth="45rem"
            mx="auto"
          />
        )
      case "assembly-instructions":
        return (
          <MaxWidthGrid key={key} className={className}>
            <AssemblyInstructions
              gridColumn={bp("1/end", "2/14")}
              items={layout.items.map((item) => ({
                id: item.id,
                image: transformImage(item.value?.image),
                label: item.value?.label || "",
                pdfUrl: item.value?.pdfUrl || "",
                videoEmbed: item.value?.videoEmbed || "",
                model: item.value?.model || "",
                configuration: item.value?.configuration || "",
              }))}
            />
          </MaxWidthGrid>
        )
      case "cta-bar":
        return (
          <MaxWidthGrid key={key} className={className}>
            <CtaBar
              gridColumn={bp("1/end", "2/14")}
              theme={layout.theme}
              text={layout.text}
              links={layout.links.map((link) => link.value)}
            />
          </MaxWidthGrid>
        )
      case "article-block":
        return (
          <MaxWidthGrid key={key} className={className}>
            <ArticleBlock
              gridColumn={bp("1/end", "2/14")}
              image={transformImage(layout.image)}
              title={layout.title}
              subtext={layout.subtext}
              to={layout.url}
              tag="Latest"
              mb="7.5rem"
            />
          </MaxWidthGrid>
        )
      case "article-block-grid":
        return (
          <MaxWidthGrid key={key} className={className}>
            <SimpleGrid
              gridColumn={bp("1/end", "2/14")}
              columns={bp(1, 3)}
              spacingX="1.25rem"
              spacingY="3.75rem"
              mb="7.5rem"
            >
              {layout.items.map(({ id, value }) => (
                <ArticleBlockItem
                  key={id}
                  image={transformImage(value?.image)}
                  title={value?.title || ""}
                  subtext={value?.subtext || ""}
                  to={value?.url || ""}
                />
              ))}
            </SimpleGrid>
          </MaxWidthGrid>
        )
      case "klaviyo-newsletter":
        return (
          <Box key={key} className={className} w="100%">
            <KlaviyoNewsletterForm textAlign="center" listId={layout.listId} />
          </Box>
        )
      case "instagram-videos":
        return (
          <MaxWidthGrid key={key} className={className}>
            <InstagramVideoGrid
              gridColumn={bp("1/end", "2/14")}
              videos={layout.videos?.map(({ value }) => ({
                vimeoId: value?.vimeoId || "",
                tag: value?.tag || "",
              }))}
            />
          </MaxWidthGrid>
        )
      case "bike-listing":
        return (
          <MaxWidthGrid key={key} className={className}>
            <BikeListing
              gridColumn={bp("1/end", "2/14")}
              bikeEntries={layout.bikes}
            />
          </MaxWidthGrid>
        )
      case "image-360":
        return (
          <Box key={key} className={className}>
            <Image360
              images={layout.images
                .map(({ value }) => transformImage(value))
                .filter((image) => !!image)}
            />
          </Box>
        )
      case "info-banner":
        return (
          <Box key={key} className={className}>
            <InfoBanner
              slides={layout.slides.map((slide) => slide.value)}
              slideDuration={(layout.slideDuration || 5) * 1000}
              textColor={layout.textColor}
              backgroundColor={layout.backgroundColor || "primary"}
              className="InfoBanner"
            />
          </Box>
        )
      case "bodyfit-quiz":
        return (
          <BodyFitQuizHorizontal
            key={key}
            currentPagePath={"/"}
            isSidebarOpen={true}
            toggleSidebar={async () => {
              await dispatch.sidebar.setAnimate(true)
              dispatch.sidebar.toggleOpen()
            }}
            isMobileQuizDrawerOpen={isOpen}
            onClose={() => {
              if (quizProgressState === "inprogress") {
                dispatch.quiz.setProgressState("paused")
              }
              onClose()
            }}
            onOpen={onOpen}
            currentQuestionNumber={currentQuestionIndex + 1}
            totalQuestionNumber={questions.length}
            canGoToNextQuestion={
              answers[questions[currentQuestionIndex]?.questionId] !== undefined
            }
            onNextQuestion={() => {
              if (
                answers[questions[currentQuestionIndex].questionId] !==
                undefined
              ) {
                dispatch.quiz.nextQuestion()
              }
            }}
            onPrevQuestion={dispatch.quiz.prevQuestion}
          />
        )

      case "line-break":
        return (
          <div key={key} className="lineBreakLayoutWrapper">
            <div className="lineBreak"></div>
          </div>
        )

      case "custom-cta": {
        const hasHeading = !!layout.heading?.trim()
        return (
          <CustomCTA
            key={key}
            heading={
              hasHeading ? (
                <span dangerouslySetInnerHTML={{ __html: layout.heading }} />
              ) : undefined
            }
            links={layout.links.map((link) => link.value)}
            mt={hasHeading ? headingMarginTop : "1rem"}
            mb={headingMarginBottom}
          />
        )
      }
      case "bike-details":
        return (
          <BikeDetails
            key={key}
            className={className}
            gridColumn={bp("1/end", "2/14")}
            bikeEntries={layout.bikes}
          />
        )
      case "bike-bulk-content":
        return (
          <BikeBulkContent
            key={key}
            className={className}
            gridColumn={bp("1/end", "2/14")}
            sectiontitle={layout.sectiontitle}
            features={layout.features}
            body={layout.body}
            image={transformImageWithOptions(layout.image)}
            bikeEntries={layout.bikes}
          />
        )
      case "blog-related-stories":
        let allSelectedRelatedBlogIds = layout.blogs.map(
          (blog) => blog.value.entry.entryId
        )
        return (
          <BlogRelatedStoriesBlock
            key={key}
            allSelectedRelatedBlogIds={allSelectedRelatedBlogIds}
            //@ts-ignore
            className={className}
          />
        )
      case "related-bikes":
        return (
          <MaxWidthGrid key={key} className={className}>
            <RelatedBikes
              gridColumn={bp("1/end", "3/13")}
              bikeEntries={layout.bikes}
              imageMap={imageMap}
            />
          </MaxWidthGrid>
        )
      case "pr-articles":
        return (
          <MaxWidthGrid key={key} className={className}>
            <SimpleGrid
              gridColumn={bp("1/end", "1/end")}
              columns={bp(1, 1)}
              spacingX="1.25rem"
              spacingY="3.75rem"
              className="PrArticlesSection"
            >
              <PrArticles
                imageMap={imageMap}
                colorScheme={layout.colorScheme}
                articles={layout.articles}
                logoGrid={layout.logoGrid}
              />
            </SimpleGrid>
          </MaxWidthGrid>
        )

      default: {
        return null
      }
    }
  })

  if (delayRender && layoutBlocks[1]) {
    layoutBlocks[1] = (
      <DelayWrapper key={layoutBlocks[1].key}>{layoutBlocks[1]}</DelayWrapper>
    )
  }
  if (delayRender && layoutBlocks[2]) {
    layoutBlocks[2] = (
      <DelayWrapper key={layoutBlocks[2].key}>{layoutBlocks[2]}</DelayWrapper>
    )
  }

  return <Container {...props}>{layoutBlocks}</Container>
}

export default LayoutBuilder
